import "./certification.scss";

export default function Intro() {

  return (
    <div className="certification" id="certification">
      <h1>Certification</h1>
      <div className="certificationImg">
      <h3>Az-900</h3>
        <div className="imgContainer">
          <img src="assets/az-900.png" alt=""  onClick={()=> window.open("https://www.credly.com/badges/69220200-7f43-4f17-bef5-836def762d31/public_url", "_blank")} />
        </div>
      </div>
    </div>
  );
}
