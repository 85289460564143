export const websitePortfolio = [
    {
      id: 1,
      title: "React JS",
      img:
        "assets/logo.png",
      ref: "https://www.qathsoftwares.com",
    }
  ];
  
  export const mobileAppPortfolio = [
    {
      id: 1,
      title: "C# (Unity)",
      img: "assets/extLogo.webp",
      ref: "https://www.hippex.net/calculator",
    }
  ];
  