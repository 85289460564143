import "./contact.scss";
import { Mail } from "@material-ui/icons";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Contact :</h2>
        <div className="itemContainer">
            <Mail className="icon" />
            <span><a href="mailto:quentin@qathsoftwares.com">quentin@qathsoftwares.com</a></span>
        </div>
      </div>
    </div>
  );
}
